import { Fragment } from 'react';
import { FormattedDate } from 'react-intl';
import { DataTable, IDataTableColumn, PageHeader } from '@smart-kasa/ui';

import type { ITransition } from 'types';

interface IStockHistory {
  transitions: ITransition[];
}

export const StockHistory = ({ transitions }: IStockHistory) => {
  const columns: IDataTableColumn<ITransition>[] = [
    {
      title: 'Дата',
      key: 'createdAt',
      render: (createdAt) => (
        <FormattedDate value={createdAt} dateStyle="short" timeStyle="short" />
      ),
    },
    {
      title: 'Кількість до',
      key: 'payload',
      render: ({ changes: { count } }) => (count ? Number(count.before).toFixed(3) : '-'),
    },
    {
      title: 'Кількість після',
      key: 'payload',
      render: ({ changes: { count } }) => (count ? Number(count.after).toFixed(3) : '-'),
    },
    {
      title: 'Списано/додано',
      key: 'payload',
      render: ({ changes: { count } }) => (count ? Number(count.diff).toFixed(3) : '-'),
    },
  ];

  return (
    <Fragment>
      <PageHeader>Історія списань</PageHeader>
      <DataTable rowIdKey="id" data={transitions} columns={columns} />
    </Fragment>
  );
};
